// Other stuff
import activateTracking from './lib/tracking.js'
import 'instant.page'
import 'lazysizes'

import './components/block-links.js'
import './components/cart.js'
import './components/tabs.js'
import './components/navigation.js'
import './components/quantity-input.js'
import './components/star-input.js'
import './components/modal.js'
import './components/header-search.js'
import './components/notification-bar.js'

/*
 * Activate Utilities
 */
activateTracking()
