function scrollToHash(hash) {
  if (!hash) {
    return;
  }

  const el = document.getElementById(hash.slice(1));

  if (el) {
    el.scrollIntoView({
      behavior: 'smooth'
    });

    // Update the window location with the hash
    history.pushState(null, null, hash);

    // Ensure the element is focused after scrolling
    setTimeout(() => {
      el.focus({preventScroll: true});

      // If the element is not focusable, make it temporarily focusable
      if (el !== document.activeElement) {
        el.setAttribute('tabindex', -1);
        el.focus({preventScroll: true});

        // Remove the tabindex attribute once the element loses focus
        el.onblur = () => {
          el.removeAttribute('tabindex');
        };
      }
    }, 500); // Delay presumes scroll has completed before focusing
  }
}

export {scrollToHash};