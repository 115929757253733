// # String Manipulation functions
// -------------------------------
// Handy for moving keys from language to langage
// Could even extend the string prototype if you wanted


function getLocationObject(url) {
  var a = document.createElement('a');

  a.href = url;

  return {
    href: a.href,
    host: a.host,
    hostname: a.hostname,
    port: a.port,
    pathname: a.pathname,
    protocol: a.protocol,
    hash: a.hash,
    search: a.search
  };
}

function isInternalUrl(url) {
  var location = getLocationObject(url);

  return window.location.host === location.host;
}

function getRelativeURL(url) {
  var location = getLocationObject(url);

  return location.pathname + location.search + location.hash;
}

function arrayToSentence(arr) {
  if (arr.length === 0) {
    return '';
  }

  if (arr.length === 1) {
    return arr[0];
  }

  var clone = arr.slice(), // Be careful not to affect the original array
    last = clone.pop();

  return clone.join(', ') + ' or ' + last;
}


/*
 * Capitalize First Letter of String
 * Ex. this-string => This-string
 */

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


/*
 * Convert dashed string to camel case string
 * Ex. this-string => thisString
 */

function dashesToCamelCase(string) {
  return string.replace(/-([a-z])/g, function(g) {
    return g[1].toUpperCase();
  });
}


/*
 * Convert dashed string to a capitalized method name for our JS
 * Ex. this-string => ThisString
 */

function dashesToMethodName(string) {
  return capitalizeFirstLetter(dashesToCamelCase(string));
}


/*
 * Convert underscored string to camel case
 * Ex. this_string => thisString
 */

function underscoreToCamelCase(string) {
  return string.replace(/_([a-z])/g, function(g) {
    return g[1].toUpperCase();
  });
}


/*
 * Convert dashed string to a capitalized method name for our JS
 * Ex. this_string => ThisString
 */

function underscoreToMethodName(string) {
  return capitalizeFirstLetter(underscoreToCamelCase(string));
}

/*
 * Get Parameters from Query String
 */

function getParameterByName(name, url = window.location.href) {
  var paramName = name.replace(/[[\]]/g, '\\$&'),
    regex,
    results;

  regex = new RegExp('[?&]' + paramName + '(=([^&#]*)|&|#|$)');
  results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// Export the functions so this file can be used as a JS module rather than a normal JS file.
export {getLocationObject, isInternalUrl, getRelativeURL, arrayToSentence, capitalizeFirstLetter, dashesToCamelCase, dashesToMethodName, underscoreToCamelCase, underscoreToMethodName, getParameterByName};
