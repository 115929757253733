import dataLayer from '../utilities/data-layer.js';
import {getLocationObject} from '../utilities/strings.js';
import {scrollToHash} from '../utilities/scrolling.js';

// Add event listener
function activateTracking() {
  window.addEventListener('click', (ev) => {
    const clicked = ev.target.closest(['a', 'button']);

    // If the element has tracking information...
    if (clicked && clicked.dataset.tracking) {
      // Buttons are easy. Just log the event and presume that whatever else they are supposed to do is happening.
      if (clicked.nodeName === 'BUTTON') {
        dataLayer.push(JSON.parse(clicked.dataset.tracking));
      } else if (clicked.nodeName === 'A') {
        // Don't handle any tracking if the the link points to the current location
        if (clicked.href === window.location.href) {
          return;
        }

        // If link has tracking data, track the click. Otherwise, ignore.
        const metaKeyDown = ev.metaKey;

        ev.preventDefault(); // Delay the click for now.

        try {
          handleTracking(clicked, metaKeyDown);
        } catch (error) {
          console.error(error);
          goToLink(clicked, metaKeyDown);
        }
      }
    }
  }, true);
}

function handleTracking(link, metaKeyDown) {
  const eventParams = JSON.parse(link.dataset.tracking);
  let successful = false;

  // Add a callback to the params we will push to GTM
  eventParams.eventCallback = () => {
    if (!successful) {
      goToLink(link, metaKeyDown);
      successful = true;
    }
  };

  // Log event to GTM. Once done, go to the link
  dataLayer.push(eventParams);

  // Set backup timeout in case it doesn’t work
  setTimeout(() => {
    if (!successful) {
      goToLink(link, metaKeyDown);
    }
  }, 500);
}

function goToLink(link, metaKeyDown) {
  const location = getLocationObject(link.href);

  if (metaKeyDown || link.target === '_blank') {
    window.open(link.href, link.target);
  } else if (location.hash && location.pathname === window.location.pathname && scrollToHash) {
    scrollToHash(location.hash);
  } else {
    window.location.href = link.href;
  }
}

// Export the functions so this file can be used as a JS module rather than a normal JS file.
export default activateTracking;
